@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  width: 100%;
  @apply bg-white-50;
  scroll-behavior: smooth;
}

body {
  @apply text-base;
  @apply text-shades-900;
  @apply bg-white-50;
  width: 100%;
}

h1 {
  font-weight: 800;
  text-align: center;
  line-height: 42px;
}

h2 {
  @apply font-bold;
  margin-bottom: 13px;
}

h3 {
  @apply text-shades-1000;
  @apply font-extrabold;
  padding-top: 7px;
}

h4 {
  @apply font-bold;
  @apply text-shades-900;
  margin-bottom: 15px;
}

body > div:first-child,
div#__next,
div#__next > div,
#root {
  min-height: 100%;
  height: 100%;
  height: -webkit-fill-available;
}

html {
  min-height: 100%;
  height: 100%;
  height: -webkit-fill-available;
  display: flex;
}

#room-builder-embedded-content-hero.override-styles {
  background-color: transparent;
}

#room-builder-embedded-content-hero.override-styles body {
  background-color: transparent !important;
}

#room-builder-embedded-content-container {
  z-index: 9999;
}

#asset-viewer-embedded-content-container {
  z-index: 9999;
}
